<template>
  <div>
    <div id="outPassenger" class="wrap-Main">
      <div class="box-S3">
        <div style="display:flex ;width: 100%;justify-content: space-evenly;">
          <div class="box-S1"
            style="color:white; background-color: green; border-radius: 10px; text-align: center; width: 290px;justify-content: center;margin:10px;">
            <div class="T-size-16"><b>{{ allowToGoOffshore }} Passenger allow to go offshore</b></div>
          </div>

          <div class="box-S1"
            style="color:white; background-color: red; border-radius: 10px; text-align: center; width: 290px;justify-content: center;margin:10px;">
            <div class="T-size-16"><b>{{ notAllowToGoOffshore }} Passenger not Allow to go offshore</b></div>
          </div>
        </div>
      </div>



      <div class="box-S1 flex-between-center" style="align-items: flex-end;">
        <div class="N-Page T-size-24"></div>
        <div class="b-numPassenger">
          <span class="icon I-passengers"></span>
          <div class="T-size-20 SemiBold">
            {{ ArrlistPassenger.length }} Passengers
          </div>
        </div>
      </div>

      <!-- showAsGrid ROW -->
      <div class="box-S4" v-if="!showAsGrid">
        <v-expansion-panels accordion class="b-accod" v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-20">Passenger List</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="wrap-passenger">
                <div class="box-4" v-for="item in ArrlistPassenger" :key="item.IdPass">
                  <div class="B-passenger TF">
                    <div class="b-person">
                      <div class="box-S1 fix-header" style="margin:10px;">
                        <div v-if="
                          item.u_imageurl == undefined ||
                          item.u_imageurl == null
                        "></div>
                        <div class="B-display person1" v-else :style="{
                          backgroundImage:
                            'url(' + item.u_imageurl + ') !important',
                        }"></div>
                      </div>
                      <div class="box-S2">
                        <div class="b-name-company">
                          <p class="t-name T-size-14">{{ item.FullName }} ({{ item.destination }})</p>
                          <div class="t-company T-size-12">
                            <span v-if="item.c_name != undefined">{{
                                item.c_name
                            }}</span><span v-else>No Company</span>&nbsp;<span v-if="item.p_rfid"
                              style="color: #800080">{{ item.p_rfid }}</span>
                          </div>
                          <div class="bC" style="padding-top: 5px;">
                            <div
                              v-if="item.nurseStatusHealthScreenForm == 'Passed' && item.nurseStatusScreeningCOVIDForm == 'Passed' && item.nurseStatusAlcoholUrineForm == 'Passed'"
                              class="T-size-14"
                              style="color:white; background-color: green; border-radius: 10px; text-align: center; width: 80px;">
                              <b>Allow</b>
                            </div>
                            <div v-else class="T-size-14"
                              style="color:white; background-color: red; border-radius: 10px; text-align: center; width: 80px;">
                              <b>Not allow</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 
                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC">
                          <span class="T-size-12">Destination</span>
                          <p class="T-size-16">{{ item.destination }}</p>
                        </div>
                      </div> -->

                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC">
                          <span class="T-size-12">Temperature</span>
                          <div class="T-size-16 center">
                            <v-icon v-if="item.trf_temperature_result == 'Passed'">mdi-check</v-icon>
                            <v-icon v-else>mdi-close</v-icon>
                            <p>{{ item.trf_temperature_result }}</p>
                          </div>
                        </div>
                      </div>

                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC">
                          <span class="T-size-12">Pressure</span>
                          <div class="T-size-16 center">
                            <v-icon v-if="item.trf_blood_pressure_result == 'Passed'">mdi-check</v-icon>
                            <v-icon v-else>mdi-close</v-icon>
                            <p>{{ item.trf_blood_pressure_result }}</p>
                          </div>
                        </div>
                      </div>

                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC">
                          <span class="T-size-12">Alcohol</span>
                          <div class="T-size-16 center">
                            <v-icon v-if="item.trf_blood_alcohol_result == 'Passed'">mdi-check</v-icon>
                            <v-icon v-else>mdi-close</v-icon>
                            <p>{{ item.trf_blood_alcohol_result }}</p>
                          </div>
                        </div>
                      </div>

                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC">
                          <span class="T-size-12 center">Declaration</span>
                          <div class="T-size-16 center"
                            v-if="item.consentHealthScreenForm == null && item.nurseStatusHealthScreenForm == null">
                            <v-icon>mdi-block-helper</v-icon>
                            <p>No Data</p>
                          </div>
                          <div class="T-size-16 center"
                            v-if="item.consentHealthScreenForm == null && item.nurseStatusHealthScreenForm == 'No Data'">
                            <v-icon>mdi-block-helper</v-icon>
                            <p>No Data</p>
                          </div>
                          <div class="T-size-16 center"
                            v-if="item.consentHealthScreenForm == true && item.nurseStatusHealthScreenForm == 'Wait'">
                            <v-icon>mdi-alarm</v-icon>
                            <p>Wait</p>
                          </div>
                          <div class="T-size-16 center"
                            v-if="item.consentHealthScreenForm == true && item.nurseStatusHealthScreenForm != null && item.nurseStatusHealthScreenForm == 'Passed'">
                            <v-icon>mdi-check</v-icon>
                            <p>Passed</p>
                          </div>
                          <div class="T-size-16 center"
                            v-if="item.consentHealthScreenForm == true && item.nurseStatusHealthScreenForm != null && item.nurseStatusHealthScreenForm == 'Failed'">
                            <v-icon>mdi-close</v-icon>
                            <p>Failed</p>
                          </div>
                        </div>
                      </div>

                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC">
                          <span class="T-size-12 center">Covid-19</span>
                          <div class="T-size-16 center"
                            v-if="item.consentScreeningCOVIDForm == null && item.nurseStatusScreeningCOVIDForm == null">
                            <v-icon>mdi-block-helper</v-icon>
                            <p>No Data</p>
                          </div>
                          <div class="T-size-16 center"
                            v-if="item.consentScreeningCOVIDForm == null && item.nurseStatusScreeningCOVIDForm == 'No Data'">
                            <v-icon>mdi-block-helper</v-icon>
                            <p>No Data</p>
                          </div>
                          <div class="T-size-16 center"
                            v-if="item.consentScreeningCOVIDForm == true && item.nurseStatusScreeningCOVIDForm == 'Wait'">
                            <v-icon>mdi-alarm</v-icon>
                            <p>Wait</p>
                          </div>
                          <div class="T-size-16 center"
                            v-if="item.consentScreeningCOVIDForm == true && item.nurseStatusScreeningCOVIDForm != null && item.nurseStatusScreeningCOVIDForm == 'Passed'">
                            <v-icon>mdi-check</v-icon>
                            <p>Passed</p>
                          </div>
                          <div class="T-size-16 center"
                            v-if="item.consentScreeningCOVIDForm == true && item.nurseStatusScreeningCOVIDForm != null && item.nurseStatusScreeningCOVIDForm == 'Failed'">
                            <v-icon>mdi-close</v-icon>
                            <p>Failed</p>
                          </div>
                        </div>
                      </div>

                      <div class="box-S1 flex-between-center t-detail">
                        <div class="bC">
                          <span class="T-size-12 center">Alcohol</span>
                          <div class="T-size-16 center"
                            v-if="item.consentAlcoholUrineForm == null && item.nurseStatusAlcoholUrineForm == null">
                            <v-icon>mdi-block-helper</v-icon>
                            <p>No Data</p>
                          </div>
                          <div class="T-size-16 center"
                            v-if="item.consentAlcoholUrineForm == null && item.nurseStatusAlcoholUrineForm == 'No Data'">
                            <v-icon>mdi-block-helper</v-icon>
                            <p>No Data</p>
                          </div>
                          <div class="T-size-16 center"
                            v-if="item.consentAlcoholUrineForm == true && item.nurseStatusAlcoholUrineForm == 'Wait'">
                            <v-icon>mdi-alarm</v-icon>
                            <p>Wait</p>
                          </div>
                          <div class="T-size-16 center"
                            v-if="item.consentAlcoholUrineForm == true && item.nurseStatusAlcoholUrineForm != null && item.nurseStatusAlcoholUrineForm == 'Passed'">
                            <v-icon>mdi-check</v-icon>
                            <p>Passed</p>
                          </div>
                          <div class="T-size-16 center"
                            v-if="item.consentAlcoholUrineForm == true && item.nurseStatusAlcoholUrineForm != null && item.nurseStatusAlcoholUrineForm == 'Failed'">
                            <v-icon>mdi-close</v-icon>
                            <p>Failed</p>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="box-S1 flex-between-center t-detail">
                        <div class="bC">
                          <span class="T-size-12 center">Compliance<br/>Declaration</span>
                          <div class="T-size-16 center" v-if="item.consentDeclarationForm != 'consented'">
                            <v-icon>mdi-block-helper</v-icon>
                            <p>No Data</p>
                          </div>
                          <div class="T-size-16 center" v-else>
                            <v-icon>mdi-check</v-icon>
                            <p>Pass</p>
                          </div>
                        </div>
                      </div> -->

                      <div class="b-action">
                        <div class="b-btnCheckin" style="float: right;width: 100%;">
                          <v-btn class="theme-btn-even btn-viewcheckin" style="width:80px;"
                            @click="GotoPersonal(item.IdPass, item.o_name, item.c_name, item.destination)">
                            <span class="T-size-18">View</span>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-show="ArrlistPassenger.length == 0">
                  <div class="b-nodata">
                    <div class="t-nodata">Please Select Passengers</div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div class="box-S1-3">
        <!-- <v-btn class="theme-btn-even MGB10" 
        v-if="userdata.r_id == 5"
        @click="AddPassenger()">
          <span class="I-create"></span>
          <span>Add</span>
        </v-btn> -->
      </div>
      <!-- showAsGrid button -->

      <div class="box-S4" v-show="userdata.r_id == 16">
        <div class="b-btnSave">
          <v-btn v-show="true" class="theme-btn-even btn-report center" text @click="FlightClosed()">
            <span class="T-size-18">Finish And Send Email</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import feathersClientUOA from "../../plugins/feathers-client-uoa";
export default {
  data: () => ({
    o_name: "",
    showAsGrid: false,
    userdata: "",
    ArrlistPassenger: [],
    itemsDataPassenger: [],
    arraychoospassenger: [
      {
        c_id: "",
        p_imageurl: "",
        u_id: "",
        FullName: "",
        c_name: "",
        as_id: "",
      },
    ],
    IdPass: null,
    idflight: null,
    panel: [0],

    allowToGoOffshore: 0,
    notAllowToGoOffshore: 0,
  }),
  // props: ["TypePassenger"],
  //Edit
  props: ["flightId"],
  components: {},
  async mounted() { },
  computed: {
    formTitle() {
      return this.modeEvent === "create" ? "Add" : "Edit";
    },
  },
  methods: {
    async DataPassengerFromDB(idflight) {
      try {
        var q = {};
        q.f_id = idflight;
        q.pt_id = 1;
        q.retire = 0;
        var res = await feathersClientUOA
          .service("viewcheckinpassenger-2")
          .find({ query: q });
        this.itemsDataPassenger = res.data;
        this.ArrlistPassenger = [];
        this.itemsDataPassenger.forEach((element) => {
          var datachoosepassenger = {};
          datachoosepassenger["IdPass"] = element.p_id;
          datachoosepassenger["u_id"] = element.u_id;
          datachoosepassenger["FullName"] = element.u_firstname + " " + element.u_lastname;
          datachoosepassenger["c_name"] = element.c_name;
          datachoosepassenger["remark"] = element.p_remark;
          datachoosepassenger["ap_departairport_id"] = element.ap_departairport_id;
          datachoosepassenger["departurefrom"] = element.ap_departairport_name;
          datachoosepassenger["ap_destairport_id"] = element.ap_destairport_id;
          datachoosepassenger["destination"] = element.ap_destairport_name;
          datachoosepassenger["bodyweight"] = element.p_body_weight;
          datachoosepassenger["baggageweight"] = element.p_baggage_weight;
          datachoosepassenger["c_id"] = element.c_id;
          datachoosepassenger["p_imageurl"] = element.p_imageurl;
          datachoosepassenger["checkboxinterfield"] = element.p_inter_field;
          datachoosepassenger["as_id"] = element.as_id;
          datachoosepassenger["p_checkin"] = element.p_checkin;
          datachoosepassenger["p_offload"] = element.p_offload;

          //Add
          datachoosepassenger["SafetyTrainingDayLeft"] = element.SafetyTrainingDayLeft;
          datachoosepassenger["MedicalCheckupDayLeft"] = element.MedicalCheckupDayLeft;
          datachoosepassenger["o_name"] = element.o_name;
          this.o_name = element.o_name;
          datachoosepassenger["u_imageurl"] = element.u_imageurl;

          datachoosepassenger["p_seat_no"] = element.p_seat_no;

          datachoosepassenger["as_name"] = element.as_name;
          datachoosepassenger["p_checkin"] = element.p_checkin;
          datachoosepassenger["p_checkin_date"] = element.p_checkin_date;
          datachoosepassenger["p_rfid"] = element.p_rfid;
          datachoosepassenger["retire"] = element.retire;

          datachoosepassenger["consentHealthScreenForm"] = element.mhf_consent;
          datachoosepassenger["nurseStatusHealthScreenForm"] = element.mhf_status;

          datachoosepassenger["consentScreeningCOVIDForm"] = element.mcf_consent;
          datachoosepassenger["nurseStatusScreeningCOVIDForm"] = element.mcf_status;

          datachoosepassenger["consentAlcoholUrineForm"] = element.maf_consent;
          datachoosepassenger["nurseStatusAlcoholUrineForm"] = element.maf_status;

          // datachoosepassenger["consentDeclarationForm"] = element.dc_consent;

          let arrayBags = [];
          if (element.p_baggage_weights != null) {
            arrayBags = element.p_baggage_weights.split(",");
          }

          if (arrayBags.length > 1) {
            let bags = [];
            arrayBags.forEach((element) => {
              let bag = {};
              bag.weight = element;
              bags.push(bag);
            });
            datachoosepassenger["baggageweights"] = bags;
          } else {
            let bags2 = [];
            let bag2 = { weight: 0 };
            bag2.weight = arrayBags[0];
            bags2.push(bag2);
            datachoosepassenger["baggageweights"] = bags2;
          }

          datachoosepassenger["trf_temperature1"] = element.trf_temperature1;
          datachoosepassenger["trf_temperature_result"] = element.trf_temperature_result;
          datachoosepassenger["trf_blood_pressure1"] = element.trf_blood_pressure1;
          datachoosepassenger["trf_blood_pressure2"] = element.trf_blood_pressure2;
          datachoosepassenger["trf_blood_pressure3"] = element.trf_blood_pressure3;
          datachoosepassenger["trf_blood_pressure_result"] = element.trf_blood_pressure_result;
          datachoosepassenger["trf_blood_alcohol1"] = element.trf_blood_alcohol1;
          datachoosepassenger["trf_blood_alcohol2"] = element.trf_blood_alcohol2;
          datachoosepassenger["trf_blood_alcohol_result"] = element.trf_blood_alcohol_result;

          if (element.trf_temperature_result == 'Passed' && element.trf_blood_pressure_result == 'Passed' && element.trf_blood_alcohol_result == 'Passed' && element.mhf_status == 'Passed' && element.mcf_status == 'Passed' && element.maf_status == 'Passed') {
            this.allowToGoOffshore++;
          } else {
            this.notAllowToGoOffshore++;
          }

          this.ArrlistPassenger.push(datachoosepassenger);

          this.ArrlistPassenger.sort(function (a, b) {
            return (
              (b.p_checkin === null) - (a.p_checkin === null) ||
              +(b.p_checkin > a.p_checkin) ||
              (-(b.p_checkin < a.p_checkin) &&
                (b.p_offload !== null) - (a.p_offload !== null)) ||
              +(b.p_offload < a.p_offload) ||
              -(b.p_offload > a.p_offload)
            );
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async GotoPersonal(Id, customerName, companyName, destination) {
      this.$router.push({
        path: `/formnurse/${Id}/${customerName}/${companyName}/${destination}`,
      });
    },
  },
};
</script>

<style scrop>
.center {
  text-align: center;
}

.theme-btn-even.offload {
  width: 100%;
  margin: 10px 0;
}
</style>