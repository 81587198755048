<template>
  <div id="flightList" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">ELECTRONIC FORM MUBADALA</div>
        <v-btn class="theme-btn-even" @click="DownloadPDF()" v-show="(userdata.r_id == 16) || (userdata.r_id == 2 && userdata.o_id == 4)">
          <span>Download E-FORM</span>
        </v-btn>
      </div>
    </div>
    <CompPassengerOutboundMubadalaEForm ref="CompPassengerOutboundMubadalaEForm" :customer="customerPassenger.o_name"
      :flightId="$route.params.id">
    </CompPassengerOutboundMubadalaEForm>

    <v-dialog v-model="dialogNoData" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog warning3">
            <div class="t-title">Warning</div>
            <div class="t-des">
              <div>NO DATA</div>
            </div>
            <div class="box-S4"></div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-save Bsize100" text @click="dialogNoData = false">
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import CompPassengerOutboundMubadalaEForm from "../components/checkin-form/CompPassengerOutboundMubadalaEForm";
import { Nurseform } from "../utils/NurseForm";
export default {
  data: () => ({
    idflight: "",
    userdata: "",
    customerPassenger: {},
    dialogNoData: false,
  }),
  props: [],
  components: {
    CompPassengerOutboundMubadalaEForm,
  },
  async mounted() {
    this.userdata = await this.$CurrentUser.GetCurrentUser();
    this.customerPassenger.o_name = await this.userdata.o_name;
    this.mode = await this.$route.params.mode;
    this.idflight = await this.$route.params.id;
    try {
      await this.RenderUI();
    } catch (error) {
      console.log("mounted ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error);
    }
  },
  computed: {
    formTitle() {
      return this.modeEvent === "create" ? "Add" : "Edit";
    },
  },
  methods: {
    async RenderUI() {
      //Add
      this.buttonCancel = false;
      this.buttonSave = false;

      await this.DataPassengerFromDB();
    },

    async DataPassengerFromDB() {
      try {
        this.sumPassengerOut =
          await this.$refs.CompPassengerOutboundMubadalaEForm.DataPassengerFromDB(
            this.idflight
          );
      } catch (error) {
        console.log(error);
      }
    },

    Back() {
      this.$router.go(-1);
    },

    async DownloadPDF() {
      var q = {};
      q.f_id = this.idflight;
      q.pt_id = 1;
      q.retire = 0;
      var res = await feathersClientUOA
        .service("viewcheckinpassenger-2")
        .find({ query: q });
      let listPassenger = res.data;
      if (listPassenger.length > 0) {
        if (listPassenger.filter(e => e.mhf_consent != null).length > 0) {
          this.$isLoading(true); // show loading screen
          var JSZip = require("jszip");
          const zip = new JSZip();
          for (const p of listPassenger) {
            if (p.mhf_consent != null) {
              var blobhealth = await Nurseform.PrintHealthForm(p.p_id, 3)
              zip.file(p.u_firstname + " " + p.u_lastname + "_" + p.p_id + "_Health Screening.pdf", blobhealth, { binary: true })
            }
            if (p.mcf_consent != null) {
              var blobcovid = await Nurseform.PrintCovidForm(p.p_id, 3)
              zip.file(p.u_firstname + " " + p.u_lastname + "_" + p.p_id + "_Covid Screening.pdf", blobcovid, { binary: true })
            }
            if (p.maf_consent != null) {
              var blobAlcohol = await Nurseform.PrintAlcoholForm(p.p_id, 3)
              zip.file(p.u_firstname + " " + p.u_lastname + "_" + p.p_id + "_Alcohol and drugs.pdf", blobAlcohol, { binary: true })
            }
            if (p.trf_temperature_result != null && p.trf_blood_pressure_result != null && p.trf_blood_alcohol_result != null) {
              var blobAlcohol = await Nurseform.PrintTestRecordForm(p.p_id, 3)
              zip.file(p.u_firstname + " " + p.u_lastname + "_" + p.p_id + "_Test Record Form.pdf", blobAlcohol, { binary: true })
            }
          };
          const content = await zip.generateAsync({ type: "blob" });
          const fileName = `${this.idflight}-${listPassenger[0].f_flight_no}-Mubadala E-Form.zip`;
          this.$isLoading(false); // hide loading screen
          return saveAs(content, fileName);
        } else {
          this.dialogNoData = true;
        }
      }
    },
  },
};
</script>
<style scoped>
.mdi:before,
.mdi-set {
  color: #33cc33;
}
</style>
<style>
.v-messages__message {
  color: #b0afaf !important;
}

.loading {
  /* background-color: transparent !important; */
  background-color: rgba(192, 192, 192, 0.6) !important;
}

.sr-only {
  color: black !important;
  font-size: 42px !important;
}
</style>